<template>
  <div class="chart-wrapper">
    <div class="chart-opt">
      <span>{{ title }}</span>
      <template v-if="!radial && !bar">
        <span
          class="material-symbols-outlined icon_opt"
          :class="[type === 'line' ? 'active' : '']"
          @click="changeType('line')"
        >
          show_chart
        </span>
        <span
          class="material-symbols-outlined icon_opt"
          :class="[type === 'pie' ? 'active' : '']"
          @click="changeType('pie')"
        >
          data_usage
        </span>
      </template>
    </div>
    <div class="d-flex h-500">
      <!-- <div v-show="loading" class="qt-block-ui" /> -->
      <div v-show="loading" class="loading min-h-300">
        <LoadingAnim />
      </div>
      <div v-show="!loading" class="chart chart-center">
        <template>
          <LottieAnimNoData v-show="this.data === 'none' && !this.radial" />
          <apexchart
            v-show="this.data !== 'none'"
            :class="[type === 'line' ? '' : 'd-none']"
            ref="lineChart"
            height="500"
            :options="line"
            :series="series_line"
          ></apexchart>
          <apexchart
            v-show="this.data !== 'none'"
            :class="[type === 'pie' ? '' : 'd-none']"
            ref="pieChart"
            height="500"
            :options="pie"
            :series="series_pie"
          ></apexchart>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'Chart',
  components: {
    LoadingAnim,
    LottieAnimNoData,
  },
  props: {
    format: {
      type: String,
    },
    title: {
      type: String,
    },
    pieLegendPosition: {
      type: String,
    },
    data: {
      type: [Array, String],
    },
    loading: {
      type: Boolean,
    },
    radial: {
      type: Boolean,
    },
    bar: {
      type: Boolean,
    },
  },
  data() {
    const colors = [
      '#F3B415',
      '#F27036',
      '#663F59',
      '#6A6E94',
      '#4E88B4',
      '#00A7C6',
      '#18D8D8',
      '#A9D794',
      '#46AF78',
      '#A93F55',
      '#752de6',
      '#2176FF',
      '#33A1FD',
      '#7A918D',
      '#BAFF29',
    ];

    return {
      type: 'line',
      line: {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
          },
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          formatter: (l) => l.charAt(0).toUpperCase() + l.slice(1),
        },
        markers: {
          size: 5,
        },
        colors,
      },
      series_line: [],
      pie: {
        chart: { type: 'donut' },
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            donut: {
              size: '60%',
              labels: {
                show: true,
              },
            },
          },
        },
        legend: {
          position: (this.pieLegendPosition ? this.pieLegendPosition : 'top'),
          horizontalAlign: 'center',
        },
        colors,
      },
      series_pie: [],
      stroke: {
        curve: 'straight',
      },
      totalRadial: 0,
      series_radial: [0],
      radialSemi: {
        chart: {
          type: 'radialBar',
          offsetY: -20,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: '#fff',
              strokeWidth: '97%',
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: '#999',
                opacity: 1,
                blur: 2,
              },
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: '22px',
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          colors: ['#16CD08'],
          /* type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          }, */
        },
        labels: ['Concluídos'],
      },

      // Bar Chart
      series_bar: [],
      barChart: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  watch: {
    format(newFormat) {
      this.type = newFormat;
    },
    loading(newLoading) {
      if (this.data === 'none') {
        this.reset();
        return;
      }
      if (!newLoading) this.render();
    },
  },
  methods: {
    changeType(type) {
      this.type = type;
      this.$emit('type', type);
    },
    reset() {
      this.series_line = [];
      this.series_pie = [];
      this.series_radial = [0];
      if (this.$refs.lineChart) this.$refs.lineChart.updateSeries(this.series_line);
      if (this.$refs.pieChart) this.$refs.pieChart.updateSeries(this.series_pie);
      if (this.$refs.radialSemiChart) this.$refs.radialSemiChart.updateSeries(this.series_radial);
      this.totalRadial = 0;
    },
    render() {
      if (this.data.length > 0) {
        console.log('Chart::render', this.data);
        const lineSeries = [];
        const pieSeries = [];
        const radialSemiSeries = [];
        const barSeries = [];
        const labels = [];
        const xaxis = [];

        this.type = this.format || 'line';

        if (this.radial) {
          console.log('Radial:: Show me everything: ', this.data[0]);
          this.data[0].forEach((insight) => {
            radialSemiSeries.push(insight);
          });
          this.series_radial = [radialSemiSeries[0]];
          this.$refs.radialSemiChart.updateSeries(radialSemiSeries);
          this.totalRadial = this.data[1].total;
        }
        if (this.bar) {
          console.log('Bar:: Show me everything: ', this.data[0]);
          this.data[0].details.forEach((insight) => {
            if (insight.timeSpent) {
              barSeries.push(insight.timeSpent.raw);
              this.series_bar = barSeries;
              xaxis.push(insight.name);
              this.$refs.barChart.updateSeries(this.series_bar);
              this.barChart.xaxis.categories = xaxis;
              this.$refs.barChart.updateOptions(xaxis);
            }
          });
        }
        if (!this.bar && !this.radial) {
          this.data.forEach((insight) => {
            let total = 0;
            const serie = {
              name: insight.group_by,
              data: insight.values,
            };

            if (insight.group_by === this.$tc('app.delivered', 1)) {
              serie.color = '#2fbfa0';
            } else if (insight.group_by === this.$tc('app.accepted', 1)) {
              serie.color = '#788db4';
            } else if (insight.group_by === this.$tc('app.undelivered', 1)) {
              serie.color = '#F64A91';
            } else if (insight.group_by === this.$tc('app.failed', 1)) {
              serie.color = '#ff5c75';
            } else if (insight.group_by === this.$tc('app.sent', 1)) {
              serie.color = '#399AF2';
            } else if (insight.group_by === this.$tc('app.received', 1)) {
              serie.color = '#635ebe';
            } else if (insight.group_by === this.$tc('app.sending', 1)) {
              serie.color = '#FFCE67';
            }

            lineSeries.push(serie);
            insight.values.forEach((value) => {
              total += value[1];
            });
            labels.push(insight.group_by);
            pieSeries.push(total);
          });
          this.$refs.lineChart.updateSeries(lineSeries);
          this.$refs.pieChart.updateSeries(pieSeries);
          this.series_pie = pieSeries;
          // this.pie.labels = labels;
          this.pie = {
            labels,
          };
          console.log('chart::', {
            lineSeries,
            pieSeries,
            labels,
          });
        }
      }
    },
    exportData(format) {
      let chartComponent;
      console.log('Chart component export: ', this.$refs);
      if (this.type === 'line') {
        chartComponent = this.$refs.lineChart.chart;
      } else {
        chartComponent = this.$refs.pieChart.chart;
      }
      console.log('Chart:: ', chartComponent);
      if (format === 'csv') {
        chartComponent.ctx.exports.exportToCSV({
          series: chartComponent.w.config.series,
          columnDelimiter: ';',
        });
      } else if (format === 'png') {
        chartComponent.ctx.exports.exportToPng(chartComponent.ctx);
      } else {
        chartComponent.ctx.exports.exportToSVG(chartComponent.ctx);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.chart {
  flex: 1 0 100%;
  &.chart-center {
    margin-top: auto;
    margin-bottom: auto;
  }
}
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  min-height: 40px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}

::v-deep .btn {
  position: relative;
  font-size: 1.5rem;
  padding: 0.5rem;

  &.calendar {
    input {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
